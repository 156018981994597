$title-font: "Roboto", sans-serif; // Example font
$subtitle-font: "Arial", sans-serif; // Example font
$text-color: #fff; // Default text color

.title {
  font-family: $title-font;
  font-size: 1.5rem;
  font-weight: bold;
  color: $text-color;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-family: $subtitle-font;
  font-size: 0.875rem;
  font-weight: 500;
  color: darken($text-color, 30%);
  margin-bottom: 0.75rem;
}

.text {
  font-family: $subtitle-font;
  font-size: 1rem;
  font-weight: normal;
  color: $text-color;
  line-height: 1.5;
}

.error {
  font-family: $subtitle-font;
  font-size: 0.875rem;
  font-weight: 500;
  color: #ff4d4f;
  margin-top: 0.5rem;
}

// Define typography sizes and attributes
$typography: (
  "Display XXXL": (
    font-size: 5rem,
    line-height: 6rem,
  ),
  "Display XXL": (
    font-size: 4rem,
    line-height: 5rem,
  ),
  "Display XL": (
    font-size: 3rem,
    line-height: 4rem,
  ),
  "Display L": (
    font-size: 2.5rem,
    line-height: 3.5rem,
  ),
  "Display M": (
    font-size: 2rem,
    line-height: 3rem,
  ),
  "Display S": (
    font-size: 1.5rem,
    line-height: 2rem,
  ),
  "Text XL": (
    font-size: 1.25rem,
    line-height: 1.75rem,
  ),
  "Text L": (
    font-size: 1rem,
    line-height: 1.5rem,
  ),
  "Text M": (
    font-size: 0.875rem,
    line-height: 1.25rem,
  ),
  "Text S": (
    font-size: 0.75rem,
    line-height: 1.125rem,
  ),
);

@mixin typography($name, $props) {
  $class-name: to-lower-case(str-replace($name, " ", "-"));

  .#{$class-name} {
    font-size: map-get($props, font-size);
    line-height: map-get($props, line-height);
    color: rgba(255, 255, 255);

    &.regular {
      font-weight: normal;
    }

    &.medium {
      font-weight: 500;
    }

    &.bold {
      font-weight: bold;
    }

    &.uppercase {
      text-transform: uppercase;
    }
  }
}

// Helper function to replace spaces with dashes
@function str-replace($string, $search, $replace) {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-slice($string, $index + str-length($search));
  }
  @return $string;
}

// Generate styles using the mixin
@each $name, $props in $typography {
  @include typography($name, $props);
}
