@import "src/assets/stylesheets/base/colors";

.playerListContainer {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.playerImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.playerImageContainer {
  @extend .s-shadow;
  width: 60px;
  height: 60px;
  border: 1px solid $white-alpha-20;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 1920px) and (min-height: 1080px) {
    width: 80px;
    height: 80px;
  }
}

.capitalLetter {
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;
}
