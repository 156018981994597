@import "src/assets/stylesheets/base/colors";

.shining {
  position: absolute;
  width: 100%;
  height: 100%;

  border-radius: 0.5rem;
  z-index: 2;
  overflow: hidden;
  pointer-events: none;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: shining 10s infinite;

    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.5)),
      color-stop(99%, rgba(128, 186, 232, 0)),
      color-stop(100%, rgba(125, 185, 232, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0% rgba(255, 255, 255, 0.8) 50% rgba(128, 186, 232, 0) 99% rgba(125, 185, 232, 0) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* IE10+ */
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
  }
}

.leaderboardModalContainer {
  width: 1000px !important;
}

.rankingTable {
  width: 100%;
  border-collapse: collapse;

  span {
    text-align: center;
    &:nth-child(2) {
      text-align: left;
    }
  }
}

.wrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 7rem 1fr 7rem 7rem 7rem 8.5rem;
}

.header {
  //@extend .bg-dark; TODO comment back if the new background not helps.
  position: sticky;
  top: -1px;
  padding-top: 16px;
  padding-bottom: 8px;
  margin-left: -1px;

  z-index: 1;
}

.largeItemContainer {
  height: 80px; //HERE
  background-color: $white-alpha-5;
  border: 0.0625rem solid $white-alpha-15;
  border-radius: 1rem;
}

.playerCard {
  display: flex;
  align-items: center;

  margin-bottom: 0.5rem;
}

.playerDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.0625rem solid $white-alpha-15;
}

.playerPlacements {
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 1rem;
}

.playerInfo {
  display: flex;
  align-items: center;
}

.playerAvatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  @media (min-width: 36rem) {
    width: 4.7rem;
    height: 4.7rem;
  }
}

.playerScore {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-alpha-5;

  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;

  border-left: 0.0625rem solid $white-alpha-15;
}

@keyframes shining {
  0% {
    transform: translateX(-1800px);
  }

  100% {
    transform: translateX(1800px);
  }
}

@keyframes fallIn {
  0% {
    opacity: 0;
    transform: translateY(-9.375rem);
  }
  50% {
    opacity: 1;
    transform: translateY(0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  height: 88px; //HERE TOO
  position: relative;
  overflow: hidden;
  animation: fallIn 0.8s ease-in-out forwards;
  opacity: 0; // Ensure items are initially hidden
  border-radius: 1rem;

  //margin-bottom: 1rem;

  &.autoScroll {
    padding: 0;
  }

  &.second {
    .detailsContainerBorder {
      background: linear-gradient(180deg, #dedeea 0%, #686877 100%);

      .detailsContainer {
        background: linear-gradient(180deg, #60606a 0%, #a9a9b8 100%);
      }
    }

    .avatarContainer {
      background: linear-gradient(180deg, #dddde9 0%, #696977 100%);
    }
  }

  &.third {
    .detailsContainerBorder {
      background: linear-gradient(180deg, #f3c3b2 0%, #ca6839 100%);

      .detailsContainer {
        background: linear-gradient(180deg, #8b4422 0%, #db9573 100%);
      }
    }

    .avatarContainer {
      background: linear-gradient(180deg, #e3c4b9 0%, #ca6939 100%);
    }
  }

  .detailsContainerBorder {
    position: relative;
    background: linear-gradient(180deg, #fbecb1 0%, #e19212 100%);
    box-shadow:
      0rem 0.25rem 0.5rem -0.125rem rgba(16, 24, 40, 0.1),
      0rem 0.125rem 0.25rem -0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 1rem;
    padding: 0.125rem;
    z-index: 1;

    .detailsContainer {
      background: linear-gradient(180deg, #cb8709 0%, #f5c658 100%);
      border-radius: 1rem;

      overflow: hidden;
    }
  }
}

.playerImageContainer {
  @extend .s-shadow;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 36rem) {
    width: 4.7rem;
    height: 4.7rem;
  }
}

.capitalLetter {
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: bold;

  &.placement {
    font-size: 7rem;
  }
}

@mixin linear-border($border-radius: 12px, $background) {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 8px; // Matches the border thickness
    border-radius: $border-radius;
    background: $background;

    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);

    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none; // Prevents interaction issues
  }
}

.leaderboardItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaderboardContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 88px;
  padding-bottom: 36px;

  position: relative;

  .middle {
    order: 1;
  }

  .left {
    order: 0;
  }

  .right {
    order: 2;
  }
}

.placementAvatarContainer {
  position: relative;
  border-radius: 100%;
  width: 164px;
  height: 164px;

  overflow: hidden;

  @extend .s-shadow;

  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &.first {
    @include linear-border(100%, linear-gradient(180deg, #cb8709 0%, #f5c658 100%));
    width: 216px;
    height: 216px;
  }

  &.second {
    @include linear-border(100%, linear-gradient(180deg, #60606a 0%, #a9a9b8 100%));
  }

  &.third {
    @include linear-border(100%, linear-gradient(180deg, #8b4422 0%, #db9573 100%));
  }
}

.placementAvatar {
  width: 149px;
  height: 149px;
  border-radius: 100%;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: center;

  &.first {
    width: 201px;
    height: 201px;
  }
}

.leaderboardResult {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.placementBadge {
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  &.first {
    .badgeBorder {
      background: linear-gradient(180deg, #fbecb1 0%, #e19212 100%);

      .badgeContainer {
        background: linear-gradient(180deg, #cb8709 0%, #f5c658 100%);
      }
    }
  }

  &.second {
    .badgeBorder {
      background: linear-gradient(180deg, #dedeea 0%, #686877 100%);

      .badgeContainer {
        background: linear-gradient(180deg, #60606a 0%, #a9a9b8 100%);
      }
    }
  }

  &.third {
    .badgeBorder {
      background: linear-gradient(180deg, #f3c3b2 0%, #ca6839 100%);

      .badgeContainer {
        background: linear-gradient(180deg, #8b4422 0%, #db9573 100%);
      }
    }
  }

  .badgeBorder {
    width: 100%;
    height: 100%;
    position: relative;
    box-shadow:
      0rem 0.25rem 0.5rem -0.125rem rgba(16, 24, 40, 0.1),
      0rem 0.125rem 0.25rem -0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 100%;
    padding: 2px;

    z-index: 2;

    .badgeContainer {
      width: 100%;
      height: 100%;
      border-radius: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      overflow: hidden;
    }
  }
}
