.wrapper {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;

  padding: 1rem;

  //if width bigger than 500px then bigger padding
  @media (min-width: 768px) {
    padding: 2rem;
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-50 {
  width: 50%;
}

.h-50 {
  height: 50%;
}
