@import "src/assets/stylesheets/abstracts";
@import "src/assets/stylesheets/base/colors";

.modalContainer {
  @extend .background-blur-s;
  @extend .white-alpha-40;
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
}

.modal {
  display: flex;
  flex-direction: column;
  line-height: 1.25rem;
  opacity: 0;
  position: fixed;
  left: 50%;
  top: -200px;
  transform: translateX(-50%);
  transition: top 700ms cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 268px;
  max-height: 95%;

  &.isLoaded {
    opacity: 1;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.modalContent {
  //@extend .bg-dark;
  @extend .theme-background;
  @extend .l-shadow;
  border: 1px solid $white-alpha-20;
  border-radius: 1rem;
  box-shadow:
    0 1rem 2.25rem rgba(0, 0, 0, 0.25),
    0 1rem 1rem rgba(0, 0, 0, 0.07);
  padding: 1rem;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalBody {
  flex: 1 1 auto;
  overflow-y: hidden;
}

.animatedText {
  opacity: 0;
  animation: glow 700ms forwards;
}

@keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/*
.basic-background {
  border: none;
  background: unset;

  width: 100%;
  height: 100%;

  outline: none;
  text-decoration: none;

  position: absolute;
  overflow: hidden;

  background: radial-gradient(
    circle at 50% 45%,
    #3d1b02 10%,
    // Warm dark brown center
    #311302 25%,
    // Deep brown transition
    #2f0216 50%,
    // Dark red surrounding the center
    #2e0412 75%,
    // Dark reddish-brown outer areas
    #2f0219 100% // Deep maroon at the edges
  );

  z-index: -1;
}
*/
