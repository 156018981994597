.swiper-pagination {
  bottom: 10px !important; /* Ensure pagination stays at the bottom */
}

.swiper-button-next,
.swiper-button-prev {
  bottom: 0px !important; /* Align navigation with pagination */
  top: auto !important; /* Remove default top alignment */
  color: #ffffff !important;
  width: 44px !important;
  height: 44px !important;
  z-index: 11 !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 1.5em !important;
}

.swiper-pagination-bullet {
  background: white !important;
  width: 1rem !important;
  height: 1rem !important;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
}

.swiper-button-prev {
  left: 10% !important; /* Align left */
}

.swiper-button-next {
  right: 10% !important; /* Align left */
}

@media (min-width: 768px) {
  .swiper-button-prev {
    left: 30% !important; /* Align left */
  }

  .swiper-button-next {
    right: 30% !important; /* Align left */
  }
}
