.board-status-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 144px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  backdrop-filter: blur(64px);

  &.offline {
    background: rgba(222, 52, 52, 0.3);
    border-bottom: 2px solid rgba(222, 52, 52, 1);
  }

  &.online {
    background: rgba(88, 140, 34, 0.3);
    border-bottom: 2px solid rgba(88, 140, 34, 1);
  }

  &.animate-in {
    visibility: visible;
    animation: slideIn 1s forwards;
  }

  &.animate-out {
    animation: slideOut 1s forwards;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
    visibility: hidden;
  }
}
