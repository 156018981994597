.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

@mixin linear-border($border-radius: 12px) {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 1px; // Matches the border thickness
    border-radius: $border-radius;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);

    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none; // Prevents interaction issues
  }
}

@mixin linear-border-primary($border-radius: 12px) {
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 1px; // Matches the border thickness
    border-radius: $border-radius;
    background: linear-gradient(180deg, #f0a240 0%, #e76a27 100%);

    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);

    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none; // Prevents interaction issues
  }
}
