@import "src/assets/stylesheets/base/colors";

#root,
body,
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: radial-gradient(91.62% 167.32% at 65.25% 20.37%, #2c2724 0%, #141211 100%);

  font-family: "Roboto Condensed", sans-serif;
}

* {
  scrollbar-width: thin; /* Makes the scrollbar thin */
  scrollbar-color: $white-alpha-20 transparent; /* Sets thumb and track colors */
}

/* width */
::-webkit-scrollbar {
  width: 0.375rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $white-alpha-20;
  border-radius: 1rem;
}

.slick-slider {
  padding: 0 !important;
  margin: 0 !important;
}

.slick-slide {
  padding: 0 !important;
  margin: 0 !important;

  border: 0 !important;
}

.slick-list {
  padding: 0 !important;
}

.slick-track {
  gap: 0 !important; /* Removes any spacing between slides */
}
