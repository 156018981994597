@import "src/assets/stylesheets/base/base";
@import "src/assets/stylesheets/base/colors";

$playerCardSmallSize: 280px;
$playerCardSize: 352px;

.setupPlayers {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .animatedText {
    opacity: 0;

    color: $white-alpha-70; // Soft grey color as in the picture
    margin-top: 20px;

    animation: glow 2s infinite alternate;
  }

  @keyframes glow {
    0% {
      opacity: 0.2;
      text-shadow:
        0 0 5px rgba(255, 255, 255, 0.8),
        0 0 0px rgba(200, 200, 200, 0.6),
        0 0 5px rgba(150, 150, 150, 0.4);
    }

    100% {
      text-shadow:
        0 0 15px rgba(255, 255, 255, 1),
        0 0 0px rgba(200, 200, 200, 0.8),
        0 0 15px rgba(150, 150, 150, 0.6);
      opacity: 1;
    }
  }
}

.title {
  position: absolute;
  top: 60px;
}

.playerContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin-top: 70px;

  max-width: calc(4 * $playerCardSmallSize + 3 * 40px); // Limit to 4 items per row

  @media (min-width: 1920px) and (min-height: 1080px) {
    max-width: calc(4 * $playerCardSize + 3 * 40px); // Limit to 4 items per row
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.playerItem {
  @extend .background-blur-s;
  @extend .s-shadow;
  @extend .bg-white-alpha-10;
  @include linear-border(1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 1rem;
  text-align: center;
  width: $playerCardSmallSize;
  height: $playerCardSmallSize;

  @media (min-width: 1920px) and (min-height: 1080px) {
    width: $playerCardSize;
    height: $playerCardSize;
  }

  animation: fadeInUp 0.5s ease-in-out;
}

.playerImage {
  border-radius: 1rem;
  width: $playerCardSmallSize;
  height: $playerCardSmallSize;

  @media (min-width: 1920px) and (min-height: 1080px) {
    width: $playerCardSize;
    height: $playerCardSize;
  }
}

.capitalName {
  font-size: 12rem;
  color: #ffffff;
  font-weight: bold;
}

.videoBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
