@import "src/assets/stylesheets/abstracts";
@import "src/assets/stylesheets/base/base";
@import "src/assets/stylesheets/base/colors";

.container {
  width: 100%;
  height: 100%;
}

.gameIcon {
  width: 150px;
  height: 75px;

  @media (min-width: 768px) {
    width: 250px;
    height: 125px;
  }

  @media (min-width: 1200px) {
    width: 357.5px;
    height: 195px;
  }
}

.gameName {
  @extend .primary;
  margin-top: 1.5rem;
  font-weight: bold;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 75px;

  @media (min-width: 768px) {
    width: 250px;
    height: 125px;
    font-size: 2rem;
  }

  @media (min-width: 1200px) {
    width: 357.5px;
    height: 195px;
    font-size: 3.5rem;
  }
}

// Tutorial screen

.tutorialScreen {
  display: flex;
  flex-direction: column;

  padding: 0px 110px 110px 110px;

  color: white;

  margin-top: auto;

  &.isModal {
    margin-top: 0;
    padding: 60px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.videoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  @media (min-width: 768px) {
    width: 50%;
  }
}

.video {
  z-index: 1;
  pointer-events: none; // Disable pointer events to prevent user interaction

  width: 315px;
  height: 315px;
  object-fit: cover;
  border-radius: 16px;

  margin-right: auto;

  @media (min-width: 768px) {
    width: 335px;
    height: 335px;
  }

  @media (min-width: 992px) {
    width: 430px;
    height: 430px;
  }

  @media (min-width: 1200px) {
    width: 550px;
    height: 550px;
  }

  @media (min-width: 1920px) {
    width: 650px;
    height: 650px;

    &.isModal {
      width: 600px;
      height: 600px;
    }
  }

  @media (min-width: 1920px) and (min-height: 1080px) {
    width: 750px;
    height: 750px;

    &.isModal {
      width: 700px;
      height: 700px;
    }
  }
}

.accordionContainer {
  width: 100%;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    margin: 0;
    width: 50%;
  }
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordionItem {
  @extend .s-shadow, .background-blur-l;
  @include linear-border(16px);
  background: $white-alpha-10;
  padding: 24px 32px;
  border-radius: 16px;
  position: relative;
  margin-bottom: 24px;

  &.active {
    @include linear-border-primary(16px);

    box-shadow:
      0px 10px 48px 0px #ae71291a,
      -8px -8px 24px 0px #e26b2a33,
      0px 12px 24px 0px #e8cd6e0d;
  }

  width: 272px;

  @media (min-width: 576px) {
    width: 100%;
  }
}

.accordionContent {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.accordionItem.active .accordionContent {
  max-height: 100%;
  opacity: 1;
}

.progressContainer {
  position: absolute;
  top: 7%;
  left: 12px;
  width: 5px;
  height: 85%;
}

.progressBar {
  width: 100%;
  border-radius: 24px;
  background: linear-gradient(180deg, #f0a240 0%, #e76a27 100%);
}

.tutorialModalContainer {
  width: 90% !important;
}
