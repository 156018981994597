@import 'src/assets/stylesheets/base/colors';

.footer {
  @extend .background-blur-l, .bg-white-alpha-5;
  height: 2.375rem;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  display: flex;
  align-items: center;

  justify-content: center;

  border-top: 1px solid $white-alpha-10;

  padding: 1rem;

  //if width bigger than 500px then bigger padding
  @media (min-width: 768px) {
    padding: 1rem 2rem;
  }

  &.increased {
    align-items: start;
    height: 4.9375rem;
  }
}
