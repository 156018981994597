@import "src/assets/stylesheets/abstracts";
@import "src/assets/stylesheets/base/base";
@import "src/assets/stylesheets/base/colors";

.gameListContainer {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}

.gameContainer {
  position: relative;
  @extend .l-shadow;
  width: 288px;
  height: 204px;

  overflow: hidden;

  border-radius: 1rem;
  border: 1px solid rgb(95, 95, 95);

  background: #ffffff1a;

  @media (min-width: 576px) {
    width: 512px;
    height: 300px;
  }

  @media (min-width: 768px) {
    width: 332px;
    height: 220px;
  }

  @media (min-width: 992px) {
    width: 288px;
    height: 220px;
  }

  @media (min-width: 1200px) {
    width: 312px;
    height: 220px;
  }

  @media (min-width: 1920px) {
    width: 400px;
    height: 281px;
  }

  @media (min-width: 1920px) and (min-height: 1080px) {
    width: 500px;
    height: 352px;
  }

  margin: 0.75rem;

  opacity: 0;
  transform: translateY(-50px);
  animation: fallAnimation 0.5s ease-out forwards;

  &.disabled {
    cursor: not-allowed;
    animation: fallAnimationDisabled 0.5s ease-out forwards;
  }
}

@keyframes fallAnimation {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fallAnimationDisabled {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 0.5;
    transform: translateY(0);
  }
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;

  padding: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    @extend .primary;
    top: 20px;
    font-weight: bold;
    font-size: 2.5rem;
  }
}

.gameVideoBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.comingSoonBadge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: $primary;
  padding: 0.5rem;
  border-radius: 0.5rem;
  z-index: 2;

  opacity: 0;
  animation: fallAnimation 0.5s ease-out forwards;
}
