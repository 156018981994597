// COLORS
$primary: #e76a27;
$primary-light: #f4a040;
$light-grey: #606060;
$red: #f25f5f;
$yellow: #ffc700;
$dark-1: #0c0c0c;
$dark-2: #1b1c1b;
$dark-3: #282928;
$dark-4: #444942;
$grey-1: #6c7468;
$grey-2: #929c8c;
$grey-3: #aaaaaa;
$grey-4: #dadada;
$light-1: #f7f7f7;
$light-2: #ffffff;
$black: #000000;

// Player colors
$green: #598d22;
$cyan: #28c8be;
$blue: #385cb3;
$purple: #6733b2;
$pink: #b94d90;
$red: #bf3430;
$orange: #b8591b;
$gray: #b3b2b2;

//White colors
$white: #ffffff;
$white-alpha-90: rgba(255, 255, 255, 0.9);
$white-alpha-70: rgba(255, 255, 255, 0.7);
$white-alpha-40: rgba(255, 255, 255, 0.4);
$white-alpha-20: rgba(255, 255, 255, 0.2);
$white-alpha-15: rgba(255, 255, 255, 0.15);
$white-alpha-10: rgba(255, 255, 255, 0.1);
$white-alpha-5: rgba(255, 255, 255, 0.05);

// Solid background Colors
.bg-primary {
  background-color: $primary;
}
.bg-dark {
  background-color: #262424;
}
.bg-white {
  background-color: #ffffff;
}
.bg-white-alpha-70 {
  background-color: $white-alpha-70;
}
.bg-white-alpha-40 {
  background-color: $white-alpha-40;
}
.bg-white-alpha-20 {
  background-color: $white-alpha-20;
}
.bg-white-alpha-15 {
  background-color: $white-alpha-15;
}
.bg-white-alpha-10 {
  background-color: $white-alpha-10;
}
.bg-white-alpha-5 {
  background-color: $white-alpha-5;
}

// Player background colors
.bg-green {
  background-color: $green !important;
}

.bg-cyan {
  background-color: $cyan !important;
}

.bg-blue {
  background-color: $blue !important;
}

.bg-purple {
  background-color: $purple !important;
}

.bg-pink {
  background-color: $pink !important;
}

.bg-red {
  background-color: $red !important;
}

.bg-orange {
  background-color: $orange !important;
}

.bg-gray {
  background-color: $gray !important;
}

// Solid Colors
.primary {
  color: $primary;
}
.dark {
  color: #262424;
}
.white {
  color: #ffffff;
}
.danger {
  color: $red;
}
.white-alpha-70 {
  color: $white-alpha-70;
}
.white-alpha-40 {
  color: $white-alpha-40;
}
.white-alpha-20 {
  color: $white-alpha-20;
}
.white-alpha-15 {
  color: $white-alpha-15;
}
.white-alpha-10 {
  color: $white-alpha-10;
}
.white-alpha-5 {
  color: $white-alpha-5;
}

// Gradients
.primary-gradient {
  background: linear-gradient(180deg, #f4a040 15.68%, #e7a627 45%, #f29f37 65%, #f4a040 82.02%);
}
.primary-vertical {
  background: linear-gradient(180deg, #f4a040 0%, #e7a627 100%);
}

.background {
  background: radial-gradient(180deg, #f4a040 15.68%, #e7a627 45%, #f29f37 65%, #f4a040 82.02%);
}

.vignette {
  background: radial-gradient(75.45% at 50% 0%, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 100%);
}

.border-gradient {
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
}

.gold-1 {
  background: linear-gradient(180deg, #e8d18b 0%, #c4ae78 100%);
}

.gold-2 {
  background: linear-gradient(180deg, #b28b78 0%, #c4ae78 100%);
}

.gold-3 {
  background: linear-gradient(180deg, #895955 0%, #a27470 100%);
}
.silver-1 {
  background: linear-gradient(180deg, #e1e5e5 0%, #161616 50%, #cfd6d8 100%);
}
.silver-2 {
  background: linear-gradient(180deg, #b4b4b6 0%, #808d9d 100%);
}
.silver-3 {
  background: linear-gradient(180deg, #a6a6a8 0%, #808d9d 100%);
}
.bronze-1 {
  background: linear-gradient(180deg, #bc8b89 0%, #a27470 100%);
}
.bronze-2 {
  background: linear-gradient(180deg, #bc8b89 0%, #91686d 100%);
}
.bronze-3 {
  background: linear-gradient(180deg, #814943 0%, #133210 100%);
}

// Shadow Effects
.l-shadow {
  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.25),
    0px 16px 32px rgba(0, 0, 0, 0.1);
}

.s-shadow {
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.25),
    0px 4px 8px rgba(0, 0, 0, 0.1);
}

// Background Blur Effects
.background-blur-l {
  backdrop-filter: blur(40px);
}

.background-blur-m {
  backdrop-filter: blur(20px);
}

.background-blur-s {
  backdrop-filter: blur(8px);
}

.shadow-bg {
  background: radial-gradient(95.63% 81.87% at 49.04% 18.13%, rgba(44, 39, 36, 0.4) 0%, #141211 100%);
  position: absolute;
  width: 100%;
  height: 100%;
}

@keyframes moving {
  0%,
  100% {
    transform: translateX(-80%) translateY(-60%);
  }
  50% {
    transform: translateX(-10%) translateY(-20%);
  }
}

.theme-background {
  background: radial-gradient(
    circle at 50% 45%,
    #3d1b02 10%,
    // Warm dark brown center
    #311302 25%,
    // Deep brown transition
    #2f0c02 50%,
    // Dark red surrounding the center
    #2e1304 75%,
    // Dark reddish-brown outer areas
    #2f020a 100% // Deep maroon at the edges
  );
}
