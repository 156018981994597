@import "src/assets/stylesheets/base/base";
@import "src/assets/stylesheets/base/colors";

.container {
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr; // 3/4 and 1/4 width

  padding: 40px;
}

.card {
  position: relative;
  @include linear-border(1rem);
  @extend .l-shadow;
  background: $white-alpha-5;
}

.videoContainer {
  position: relative;
  @include linear-border(1rem);
  @extend .l-shadow;
  border-radius: 16px;
  margin-right: 40px;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; // Fills the container while maintaining aspect ratio
    border-radius: 16px;
  }
}

.loginCard {
  position: relative;
  @include linear-border(1.5rem);
  @extend .l-shadow;
  border-radius: 1.5rem;
  background: $white-alpha-5;

  display: flex;
  flex-direction: column;
  height: 100%;

  padding: 48px 24px 24px 24px;
}

.qrCodeCard {
  @extend .card;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;

  padding: 24px;
}

.qrCode {
  border-radius: 8px;
}

.infoCard {
  @extend .card;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 1rem;

  padding: 40px 24px;
  ////min-width: 1920px and min-height: 1080px
  @media (min-width: 1920px) and (min-height: 1080px) {
    padding: 70px 24px;
  }
}

.badge {
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: $white-alpha-5;
  border: 1px solid $white-alpha-20;

  border-radius: 100%;

  color: $white;
}

.error {
  color: $red;
  font-size: 30px;
}

.gameListContainer {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 576px) {
    margin-top: 2rem;
  }

  @media (min-width: 768px) {
    margin-top: 2.5rem;
  }
}

.errorContainer {
  padding: 1rem;
  gap: 4px;
  background: rgba(222, 52, 52, 0.1);
  border: 1px solid rgba(222, 52, 52, 0.4);
  border-radius: 0.5rem;
}
