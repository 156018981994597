// Define your spacing scale (similar to Bootstrap's scale)
$spacings: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, auto);

// Mixin to generate classes for padding and margin
@mixin generate-spacing-classes($property, $prefix) {
  // Iterate over spacing values
  @each $value in $spacings {
    // Generate classes for all directions
    .#{$prefix}t-#{$value} {
      #{$property}-top: if($value == auto, auto, #{$value * 0.25}rem);
    }
    .#{$prefix}b-#{$value} {
      #{$property}-bottom: if($value == auto, auto, #{$value * 0.25}rem);
    }
    .#{$prefix}l-#{$value} {
      #{$property}-left: if($value == auto, auto, #{$value * 0.25}rem);
    }
    .#{$prefix}r-#{$value} {
      #{$property}-right: if($value == auto, auto, #{$value * 0.25}rem);
    }
    .#{$prefix}x-#{$value} {
      #{$property}-left: if($value == auto, auto, #{$value * 0.25}rem);
      #{$property}-right: if($value == auto, auto, #{$value * 0.25}rem);
    }
    .#{$prefix}y-#{$value} {
      #{$property}-top: if($value == auto, auto, #{$value * 0.25}rem);
      #{$property}-bottom: if($value == auto, auto, #{$value * 0.25}rem);
    }
    .#{$prefix}-#{$value} {
      #{$property}: if($value == auto, auto, #{$value * 0.25}rem);
    }
  }
}

// Generate padding and margin classes
@include generate-spacing-classes('padding', 'p');
@include generate-spacing-classes('margin', 'm');
